<template>
  <v-slider
    v-model="model[field]"
    v-bind="control"
    label=''
    thumb-label
    step="10"
    class="jexy-slider"
    @change="$emit('change')"
  />
</template>

<script>

export default {
  name: 'Select',
  inject: ['model', 'field'],
  computed: {
    field () {
      return this.$attrs.field
    },
    control () {
      return this.model.control(this.field, this.$attrs)
    }
  }

}
</script>

<style lang="scss">

.v-list-item__title {
  font-weight: bold;
}

</style>
